<template>
  <div class="container-fluid container">
    <Breadcrumb />
    <template v-if="isRoleAdmin()">
      <CreateButton />
      <el-button
        @click="
          () => {
            $router.push({ name: 'Salary' });
          }
        "
        >{{ $t("pageTitle.Salary") }}</el-button
      >
      <el-button type="success">{{ $t("pageTitle.Bill") }}</el-button>
    </template>
    <el-alert
      v-if="
        isRoleAdmin() && $store.getters['bill/unpaidUserWithoutTag'].length > 0
      "
      class="studentsError"
      type="error"
      show-icon
    >
      <h6 class="warningTitle">
        以下學生未被分類因此無法出現款項，請先到
        <router-link :to="{ name: 'tags', query: { tagType: 'student' } }">
          學生標籤
        </router-link>
        將他們添加到對應種類內唷。
      </h6>
      <UserNames
        :users="
          $store.getters['bill/unpaidUserWithoutTag'].map(
            ({ id, first_name, last_name }) => ({
              id,
              name: userMethods.displayName(first_name, last_name)
            })
          )
        "
      />
    </el-alert>
    <h1 class="text-center mt-5 mb-3">
      {{ $t("pageTitle.Bill") }}
    </h1>
    <el-tabs v-if="isRoleAdmin()" type="card" :value="$route.name">
      <el-tab-pane name="Bill">
        <template slot="label">
          <router-link class="tabLink" :to="{ name: 'Bill' }">
            {{ $t("bill.pendingReceivePayment") }}
          </router-link>
        </template>
      </el-tab-pane>
      <el-tab-pane name="Receivable">
        <template slot="label">
          <router-link class="tabLink" :to="{ name: 'Receivable' }">
            未來款項
          </router-link>
        </template>
      </el-tab-pane>
    </el-tabs>
    <template v-if="isBillList">
      <h2 v-if="isRoleAdmin()">
        {{ $t("bill.pendingReceivePayment") }}
        <el-tooltip content="30 天以內的款項" placement="top">
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </h2>
      <h2 v-else-if="isRoleParent()">
        {{ $t("bill.pendingPayment") }}
      </h2>
      <template v-if="isRoleAdmin()">
        <hr />
        <h3>篩選條件</h3>
        <el-row :gutter="20" type="flex" align="middle">
          <el-col :span="2" :lg="2" :sm="4" :xs="6" align="right">
            學生種類
          </el-col>
          <el-col
            :span="6"
            :lg="4"
            :xs="8"
            v-for="userTag in userTags"
            :key="userTag.id"
          >
            <router-link
              :to="{
                name: 'Bill',
                query: { ...$route.query, studentTagId: userTag.id }
              }"
            >
              <el-button
                :type="filterTagId === userTag.id ? 'primary' : ''"
                class="filterTagBtn"
              >
                {{ userTag.name[lang] }}
              </el-button>
            </router-link>
          </el-col>
        </el-row>
      </template>
      <hr />
      <el-table
        :data="$store.getters['bill/parentUnpaidFees']"
        :row-class-name="() => 'tableRow'"
        @row-click="enterToBillDetail"
      >
        <el-table-column :label="$t('column.parent')" width="150">
          <template slot-scope="scope">
            <template v-if="isRoleAdmin()">
              {{
                scope.row.parent_user_id
                  ? userMethods.displayName(
                      scope.row.parent_user_first_name,
                      scope.row.parent_user_last_name
                    )
                  : scope.row.parent_user_name
              }}
            </template>
            <template v-else-if="isRoleParent()">
              {{
                userMethods.displayName(
                  scope.row.parent_user.first_name,
                  scope.row.parent_user.last_name
                )
              }}
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.student')" width="200">
          <template slot-scope="scope">
            {{
              helper.displayMultipleItems(
                scope.row.parent_user_students.map(
                  ({ first_name, last_name }) =>
                    userMethods.displayName(first_name, last_name)
                )
              )
            }}
          </template>
        </el-table-column>
        <el-table-column width="200" label="Notes(For Admin)" v-if="false">
          <template slot-scope="scope">
            <el-alert
              show-icon
              v-if="scope.row.latestOrder.notes"
              type="warning"
              effect="dark"
              :closable="false"
            >
              {{ scope.row.latestOrder.notes }}
            </el-alert>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
        <el-table-column
          min-width="50"
          label="Notes (For Admin)"
          v-if="isRoleAdmin()"
        >
          <template slot-scope="scope">
            <el-alert
              v-if="scope.row.billing_notes"
              type="info"
              :closable="false"
              effect="dark"
              :description="scope.row.billing_notes"
            >
              <template slot="title" v-if="scope.row.billing_notes_by_user">
                {{
                  userMethods.displayName(
                    scope.row.billing_notes_by_user.first_name,
                    scope.row.billing_notes_by_user.last_name
                  )
                }}
              </template>
            </el-alert>
            <div>
              <el-button
                type="text"
                @click.stop="
                  setBillNotes(
                    scope.row.parent_user_id,
                    scope.row.billing_notes
                  )
                "
              >
                <i class="fa fa-edit"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="600" :label="$t('column.billingProgress')">
          <template slot-scope="scope">
            <StepOfAdmin v-if="isRoleAdmin()" :scope="scope" />
            <StepOfParent v-else-if="isRoleParent()" :scope="scope" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.amount')" width="180">
          <template slot-scope="scope">
            {{
              scope.row.latestOrder.displayTotalPrice ||
                scope.row.pendingOrderTotal
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="Edit Notes" :visible.sync="showNotes" width="50%">
        <el-form label-width="50px">
          <el-form-item label="Notes">
            <el-input type="textarea" v-model="bill.billNotes" :rows="10">
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="text-right">
              <el-button type="success" @click="updateBillNotes">
                Save
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
    <template v-if="isReceivableList">
      <h2 v-if="isRoleAdmin()">
        未來款項
        <el-tooltip content="30 天以後的款項" placement="top">
          <span class="action-icon">
            <i class="fas fa-question-circle" />
          </span>
        </el-tooltip>
      </h2>
      <hr />
      <el-table
        :data="$store.getters['bill/receivables']"
        :row-class-name="() => 'tableRow'"
        @row-click="enterToReceivableDetail"
      >
        <el-table-column :label="$t('column.parent')" width="150">
          <template slot-scope="scope">
            {{
              userMethods.displayName(
                scope.row.user.first_name,
                scope.row.user.last_name
              )
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.title')" prop="title" />
        <el-table-column :label="$t('column.amount')" width="180">
          <template slot-scope="scope">
            {{ scope.row.displayTotalPrice }}
          </template>
        </el-table-column>
      </el-table>
    </template>
    <hr class="separate-line" />
    <h2 v-if="isRoleAdmin()">{{ $t("bill.receivedTransaction") }}</h2>
    <h2 v-else-if="isRoleParent()">{{ $t("bill.paidTransaction") }}</h2>
    <el-row type="flex" align="middle">
      <el-col :span="2">
        收款日期：
      </el-col>
      <el-col :span="22">
        <el-date-picker
          placeholder="Start"
          v-model="filterDateStart"
          type="date"
          value-format="yyyy-MM-dd"
        />
        ~
        <el-date-picker
          placeholder="End"
          v-model="filterDateEnd"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-input
          ref="filterInput"
          v-model="filterConditionText"
          :placeholder="$t('bill.filterPlaceholder')"
        />
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="setFilter">
          {{ $t("button.search") }}
        </el-button>
      </el-col>
    </el-row>
    <el-table :data="$store.getters['bill/paidBills']">
      <el-table-column :label="$t('column.date')" width="180">
        <template slot-scope="scope">
          {{ instant.formatDate(scope.row.transaction_datetime) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.student')" width="180">
        <template slot-scope="scope">
          {{
            helper.displayMultipleItems(
              scope.row.order_item_names.map(({ first_name, last_name }) =>
                userMethods.displayName(first_name, last_name)
              )
            )
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.payer')" width="180">
        <template slot-scope="scope">
          {{
            userMethods.displayName(scope.row.first_name, scope.row.last_name)
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.billName')" prop="title">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'billView',
              params: { billId: scope.row.id }
            }"
          >
            {{ scope.row.title }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('column.amount')"
        prop="displayTotalPrice"
        width="180"
      />
    </el-table>
    <div class="text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="changePaidPage"
        :total="$store.getters['bill/paidBillsCount']"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "@/assets/scss/style.scss";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import tagApi from "@/apis/tag";
import UserNames from "@/components/names/UserNames";
import Breadcrumb from "@/components/Breadcrumb";
import CreateButton from "./CreateButton";
import StepOfAdmin from "./StepOfAdmin";
import StepOfParent from "./StepOfParent";
import profileApi from "@/apis/profile";
import { user, instant, helper } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.Bill")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    UserNames,
    CreateButton,
    StepOfAdmin,
    StepOfParent
  },
  mixins: [moneyMixin, roleMixin],
  computed: {
    helper() {
      return helper;
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    filterTagId() {
      return Number(this.$route.query.studentTagId || this.userTags[0].id);
    },
    isBillList() {
      return this.$route.name === "Bill";
    },
    isReceivableList() {
      return this.$route.name === "Receivable";
    }
  },
  data() {
    return {
      userTags: [],
      filterConditionText: "",
      filterDateStart: null,
      filterDateEnd: null,
      pageSize: 15,
      currentPage: 1,
      showNotes: false,
      bill: {
        id: null,
        billNotes: null
      }
    };
  },
  async created() {
    this.setQueryToState();
    if (this.isRoleAdmin()) {
      this.$store.dispatch("bill/getUnpaidUserWithoutTag");
      await this.getUserTags();
      if (this.isBillList) {
        await this.$store.dispatch("bill/getParentUnpaidFees", {
          filterTagId: this.filterTagId
        });
        this.$store.dispatch("bill/computeUnpaidFeeAndOldestUnpaidItemDate");
      } else if (this.isReceivableList) {
        await this.$store.dispatch("bill/getReceivables");
      }
    } else if (this.isRoleParent()) {
      await this.$store.dispatch("bill/getUnpaidBills");
    }
    this.getPaidList();
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") this.setFilter();
    });
  },
  methods: {
    setBillNotes(id, notes) {
      this.showNotes = true;
      this.bill = {
        id: id,
        billNotes: notes
      };
    },
    async updateBillNotes() {
      await profileApi.patchBasicProfileByAdmin(this.bill.id, {
        billing_notes: this.bill.billNotes
      });
      this.showNotes = false;
      await this.$store.dispatch("bill/getParentUnpaidFees", {
        filterTagId: this.filterTagId
      });
    },
    setQueryToState() {
      if (this.$route.query.page) {
        this.currentPage = Number(this.$route.query.page);
      }
      if (this.$route.query.searchText) {
        this.filterConditionText = this.$route.query.searchText;
      }
      if (this.$route.query.dateStart) {
        this.filterDateStart = this.$route.query.dateStart;
      }
      if (this.$route.query.dateEnd) {
        this.filterDateEnd = this.$route.query.dateEnd;
      }
    },
    async getUserTags() {
      const { tags } = await tagApi.fetchTags("student");
      const filterTags = ["台灣學生", "中國學生"];
      this.userTags = filterTags.map(tag =>
        tags.find(({ name: { tw } }) => tw === tag)
      );
    },
    enterToBillDetail(bill) {
      if (bill.latestOrder.id) {
        this.$router.push({
          name: "billView",
          params: {
            billId: bill.latestOrder.id
          },
          query: {
            student: bill.students[0].userName
          }
        });
      } else {
        this.$router.push({
          to: "createBillWithStudent",
          params: {
            studentId: bill.students[0].userId
          }
        });
      }
    },
    enterToReceivableDetail(receivable) {
      this.$router.push({
        name: "ReceivableView",
        params: {
          receivableId: receivable.id
        }
      });
    },
    isBillLate(date) {
      const thisMonth = moment().format("YYYY-MM-01");
      const diffMonths = moment(thisMonth).diff(
        moment(date).format("YYYY-MM-01"),
        "months"
      );
      return diffMonths > 1;
    },
    changePaidPage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page
        }
      });
    },
    setFilter() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: 1,
          searchText: this.filterConditionText,
          dateStart: this.filterDateStart,
          dateEnd: this.filterDateEnd
        }
      });
    },
    async getPaidList() {
      await this.$store.dispatch("bill/getPaidBills", {
        search: this.filterConditionText,
        dateStart: this.filterDateStart,
        dateEnd: this.filterDateEnd,
        pageSize: this.pageSize,
        page: this.currentPage
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-pagination {
  margin: 20px 0px;
}

::v-deep .is-icon {
  width: 120px;
}

::v-deep .el-step__main {
  text-align: center;
  width: 120px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}

::v-deep .tableRow {
  cursor: pointer;
}

.warningTitle {
  color: inherit;
}

::v-deep .el-alert__description {
  font-size: 14px;
}

.filterTagBtn {
  width: 100%;
}

.container-fluid {
  padding: 20px;
  max-width: 95vw;
}

.el-table__row:hover .el-steps ::v-deep .is-icon {
  background: rgb(245, 247, 250);
}

.error ::v-deep .is-icon {
  color: red;
}

.wrong {
  color: red;
}

::v-deep .el-tabs__item {
  position: relative;
}

.tabLink {
  font-size: 1.25rem;
  text-decoration: none;
}

.tabLink::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.studentsError {
  margin-top: 16px;
}

::v-deep .el-alert--info > .el-alert__content > .el-alert__description {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #1f9aff !important;
}
</style>
