<template>
  <div class="createButtonWrapper">
    <el-button
      circle
      type="danger"
      icon="el-icon-plus"
      class="expandButton"
      @click="switchExpandCreateButtonStatus"
    />
    <el-tooltip
      :class="
        `item ${
          expandCreateButtons ? 'expandCreateReceivableButtonTooltip' : ''
        }`
      "
      content="新增分期款項"
      placement="top"
      popper-class="tooltipColor"
    >
      <router-link :to="{ name: 'createReceivable' }">
        <el-button
          circle
          type="primary"
          icon="el-icon-plus"
          class="createButton"
        />
      </router-link>
    </el-tooltip>
    <el-tooltip
      :class="
        `item ${expandCreateButtons ? 'expandCreateBillButtonTooltip' : ''}`
      "
      content="新增近期款項"
      placement="top"
      popper-class="tooltipColor"
    >
      <router-link :to="{ name: 'createBill' }">
        <el-button
          circle
          type="primary"
          icon="el-icon-plus"
          class="createButton"
        />
      </router-link>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandCreateButtons: false
    };
  },
  methods: {
    switchExpandCreateButtonStatus() {
      this.expandCreateButtons = !this.expandCreateButtons;
    }
  }
};
</script>

<style lang="scss" scoped>
.createButtonWrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9;

  .expandButton {
    font-size: 32px;
    width: 60px;
    height: 60px;
  }

  .createButton {
    width: 40px;
    height: 40px;
    z-index: -1;
  }

  .item {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: -1;
    transition: right 0.5s;
  }

  .expandCreateBillButtonTooltip {
    right: 120px;
  }

  .expandCreateReceivableButtonTooltip {
    right: 70px;
  }
}
</style>
